export const components = [{
  id: 'get-started',
  title: 'Get Started',
  pages: [{
    id: 'introduction',
    title: 'Introduction',
    href: '/documentation/?component=introduction',
  }, {
    id: 'quick-start',
    title: 'Quick Start',
    href: '/documentation/?component=quick-start',
  }, {
    id: 'changelog',
    title: 'Changelog',
    href: '/documentation/?component=changelog',
  }],
}, {
  id: 'atoms',
  title: 'Atoms',
  pages: [{
    id: 'dark-mode-toggler',
    title: 'Dark Mode Toggler', 
    href: '/documentation/?component=dark-mode-toggler',
  }, {
    id: 'icon',
    title: 'Icon', 
    href: '/documentation/?component=icon',
  }, {
    id: 'icon-text',
    title: 'Icon Text',
    href: '/documentation/?component=icon-text',
  }, {
    id: 'image',
    title: 'Image',
    href: '/documentation/?component=image',
  }, {
    id: 'learn-more-link',
    title: 'Learn More Link',
    href: '/documentation/?component=learn-more-link',
  }],
}, {
  id: 'molecules',
  title: 'Molecules',
  pages: [{
    id: 'count-up-number',
    title: 'Count Up Number', 
    href: '/documentation/?component=count-up-number',
  }, {
    id: 'description-with-cta',
    title: 'Description Text With CTA',
    href: '/documentation/?component=description-with-cta',
  }, {
    id: 'icon-alternate',
    title: 'Icon Alternate',
    href: '/documentation/?component=icon-alternate',
  }, {
    id: 'overlapped-images',
    title: 'Overlapped Images',
    href: '/documentation/?component=overlapped-images',
  }, {
    id: 'section-header',
    title: 'Section Header',
    href: '/documentation/?component=section-header',
  }, {
    id: 'image-swiper',
    title: 'Image Swiper',
    href: '/documentation/?component=image-swiper',
  }, {
    id: 'numbers-swiper',
    title: 'Numbers Swiper',
    href: '/documentation/?component=numbers-swiper',
  }, {
    id: 'typed-text',
    title: 'Typed Text',
    href: '/documentation/?component=typed-text',
  }],
}, {
  id: 'organisms',
  title: 'Organisms',
  pages: [{
    id: 'accordion',
    title: 'Accordion', 
    href: '/documentation/?component=accordion',
  }, {
    id: 'card-base',
    title: 'Card Base',
    href: '/documentation/?component=card-base',
  }, {
    id: 'card-category',
    title: 'Card Category',
    href: '/documentation/?component=card-category',
  }, {
    id: 'card-category-link',
    title: 'Card Category Link',
    href: '/documentation/?component=card-category-link',
  }, {
    id: 'card-job',
    title: 'Card Job',
    href: '/documentation/?component=card-job',
  }, {
    id: 'card-job-company',
    title: 'Card Job Company',
    href: '/documentation/?component=card-job-company',
  }, {
    id: 'card-job-minimal',
    title: 'Card Job Minimal',
    href: '/documentation/?component=card-job-minimal',
  }, {
    id: 'card-job-tag',
    title: 'Card Job Tag',
    href: '/documentation/?component=card-job-tag',
  }, {
    id: 'card-pricing-standard',
    title: 'Card Pricing Standard',
    href: '/documentation/?component=card-pricing-standard',
  }, {
    id: 'card-product',
    title: 'Card Product',
    href: '/documentation/?component=card-product',
  }, {
    id: 'card-promo',
    title: 'Card Promo',
    href: '/documentation/?component=card-promo',
  }, {
    id: 'card-review',
    title: 'Card Review',
    href: '/documentation/?component=card-review',
  }, {
    id: 'description-list-icon',
    title: 'Description List Icon',
    href: '/documentation/?component=description-list-icon',
  }, {
    id: 'hero-background',
    title: 'Hero Background',
    href: '/documentation/?component=hero-background',
  }, {
    id: 'hero-shaped',
    title: 'Hero Shaped',
    href: '/documentation/?component=hero-shaped',
  }, {
    id: 'hero-side-image',
    title: 'Hero Side Image',
    href: '/documentation/?component=hero-side-image',
  }, {
    id: 'hero-simple-background',
    title: 'Hero Simple Background',
    href: '/documentation/?component=hero-simple-background',
  }, {
    id: 'map',
    title: 'Map',
    href: '/documentation/?component=map',
  }, {
    id: 'parallax',
    title: 'Parallax',
    href: '/documentation/?component=parallax',
  }, {
    id: 'section',
    title: 'Section',
    href: '/documentation/?component=section',
  }, {
    id: 'section-alternate',
    title: 'Section Alternate',
    href: '/documentation/?component=section-alternate',
  }],
}];